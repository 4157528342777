<template>
  <div class="booking-page">
    <m-page-title title="Booking Rules" address />
    <div class="content">
      <el-tabs
        class="tabs"
        v-model="activeName"
        type="card"
        @tab-click="tabClick"
      >
        <!-- <el-tab-pane label="Move In/Out" name="-1">
          <div class="rich-txt-box g-scroll-style">这里是Move In/Out</div>
        </el-tab-pane> -->
        <el-tab-pane
          :label="item.name"
          :name="item.id"
          v-for="item in apartmentList"
          :key="item.id"
        >
          <div class="ql-container ql-snow">
            <div
              class="ql-editor rich-txt-box g-scroll-style"
              v-html="item.myContent.html"
            ></div>
          </div>
          <div class="pat-pdf-down">
            <a
              href="javascript:void(0);"
              v-for="(item, index) in item.myContent.pdf"
              :key="index"
              @click="fileLinkToStreamDownload(item.url)"
              ><img src="@/static/icon/tools/Download.png" alt="" />{{
                item.name
              }}</a
            >
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-form
        ref="From"
        :rules="rules"
        :model="form"
        v-if="sideBarStatus !== -1"
        @submit.native.prevent
      >
        <el-form-item prop="check">
          <el-checkbox class="i-have-read" v-model="form.check">
            I have read the Booking Rules
          </el-checkbox>
        </el-form-item>
      </el-form>
      <m-to-dashboard class="btn" v-if="sideBarStatus === -1" />
      <el-button type="primary" class="btn" @click="proceedClick" v-else>
        Proceed to building information
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import validate from "@/utils/validateForm";
export default {
  name: "bookingRules",
  components: {},
  data() {
    return {
      form: { check: false },
      rules: { check: [{ validator: validate.agreementConfirm }] },
      readCheck: false,
      activeName: "-1",
      apartmentList: [
        {
          apartmentId: null,
          apartmentUnitId: "1",
          createTime: null,
          description: "",
          id: "-1",
          name: "Move In/Out",
          notificationEmail: null,
          pdf: "{}",
          photo: null,
          rule: null,
          myContent: { status: false, html: "" },
        },
      ],
      html: "",
      commFacilities: [],
    };
  },
  computed: {
    ...mapState("bar", ["sideBarStatus"]),
    ...mapState("apartment", ["room", "apartment"]),
  },
  created() {
    this.getApartment();
    // this.getGuideline("-1");
    this.getMoving();
    this.keyupSubmit();
  },
  mounted() {},
  methods: {
    // enter事件
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          if (this.sideBarStatus !== -1) {
            this.proceedClick();
          }
        }
      };
    },
    fileLinkToStreamDownload(url) {
      const fileName = "pdf";
      const xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.setRequestHeader("Content-Type", `application/pdf`);
      xhr.responseType = "blob";
      const that = this;
      xhr.onload = function () {
        if (this.status === 200) {
          // 接受二进制文件流
          const blob = this.response;
          that.downloadExportFile(blob, fileName);
        }
      };
      xhr.send();
    },
    downloadExportFile(blob, tagFileName) {
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(blob); // 创建下载的链接
      downloadElement.href = href;
      downloadElement.download = tagFileName; // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放blob对象
    },
    async proceedClick() {
      if (!(await this.$refs.From.validate())) return false;
      this.$router.push("/buildingInfo");
    },
    // 获取公共设施列表
    getApartment() {
      let params = {
        apartmentUnitId: this.room.apartmentUnitId,
      };
      this.$axios
        .get(this.$api.setApartment(this.apartment.id), {
          params,
        })
        .then((res) => {
          let list = res.data.list;
          for (let i = 0, len = list.length; i < len; i++) {
            list[i].myContent = { status: false, html: null, pdf: [] };
          }
          if (res.code === "1000") {
            this.apartmentList.push(...list);
            console.log(this.apartmentList);
          }
        });
    },
    // tabClick 点击事件
    tabClick(value) {
      const id = value.name;
      const apartment = this.apartmentList.filter((item) => item.id === id)[0];
      apartment.myContent.status || this.getGuideline(id);
    },
    //获取Moving信息
    getMoving() {
      //type 	0：搬家指南 1：修改规则 2：取消规则
      let params = {
        apartmentUnitId: this.room.apartmentUnitId,
        type: 0,
      };
      this.$axios
        .get(this.$api.getMoving(this.apartment.id), { params })
        .then((res) => {
          if (res.code === "1000" && res.data.apartmentGuideline) {
            this.$set(
              this.apartmentList[0].myContent,
              "html",
              res.data.apartmentGuideline.content
            );
            this.$set(
              this.apartmentList[0].myContent,
              "pdf",
              JSON.parse(res.data.apartmentGuideline.pdf)
            );
            this.$set(this.apartmentList[0].myContent, "status", true);
          }
        });
    },
    // 获取指南信息
    getGuideline(id) {
      console.log(id);
      if (parseInt(id) === -1) {
        return false;
      }
      this.$axios.get(this.$api.getApartmentarea(id)).then((res) => {
        if (res.code === "1000") {
          for (let i = 0; i < this.apartmentList.length; i++) {
            if (this.apartmentList[i].id === res.data.apartmentArea.id) {
              // const html = data.apartmentGuideline.content || "为空";
              // this.apartmentList[i].myContent.html = html;

              this.$set(
                this.apartmentList[i].myContent,
                "html",
                res.data.apartmentArea.rule
              );
              this.$set(
                this.apartmentList[i].myContent,
                "pdf",
                res.data.apartmentArea.pdf
              );
              this.$set(this.apartmentList[i].myContent, "status", true);
            }
          }
          console.log(this.apartmentList);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>

<style lang="scss">
.tabs {
  .el-tabs__content {
    flex: 1;
    display: flex;
    padding: 18px 24px;
    border-radius: 4px;
    border: 1px solid #d8d8dc;
    .el-tab-pane {
      flex: 1;
      display: flex;
      flex-flow: column;
    }
  }
}
</style>
